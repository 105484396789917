import CreateUserRequestData from '@/calendesk/models/DTO/Request/CreateUserRequestData'

export default class SimpleStoreProductCreatePaymentRequestData {
  public user: CreateUserRequestData
  public simple_store_product_id: number
  public quantity: number
  public promo_code: string | null
  public gdpr_fields: string | null

  public constructor (
    user: CreateUserRequestData,
    simpleStoreProductId: number,
    quantity: number,
    promoCode: string | null,
    gdprFields: string | null) {
    this.user = user
    this.simple_store_product_id = simpleStoreProductId
    this.quantity = quantity
    this.promo_code = promoCode
    this.gdpr_fields = gdprFields
  }
}
