








































































































































































































































































































import { mapActions } from 'vuex'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import PaymentActions from '@/views/components/mixins/PaymentActions'
import SimpleStoreProduct from '@/calendesk/models/DTO/Response/SimpleStoreProduct'
import SimpleStoreProductCreatePaymentRequestData
  from '@/calendesk/models/DTO/Request/SimpleStoreProductCreatePaymentRequestData'
import dialogMixins from '@/components/dialogs/mixins/DialogMixins'
import { errorNotification } from '@/calendesk/prototypes/notifications'
import CtaButtonData from '@/calendesk/models/CtaButtonData'
import parseCheckboxDescription from '@/calendesk/tools/parseCheckboxDescription'
import {
  email, equalsValue,
  lowercase,
  maxChars,
  minChars,
  number,
  required,
  specialChar,
  uppercase
} from '@/calendesk/forms/validators'
import CPhoneInput from '@/views/components/CPhoneInput.vue'
import CompanyDataActions from '@/calendesk/sections/section/shared/mixins/CompanyDataActions'
import CCountryAutocomplete from '@/components/CCountryAutocomplete.vue'
import GdprFieldData from '@/calendesk/models/GdprFieldData'
import GdprFieldUserData from '@/calendesk/models/GdprFieldUserData'
import CreateUserRequestData from '@/calendesk/models/DTO/Request/CreateUserRequestData'
import UserDefaultAddressRequestData from '@/calendesk/models/DTO/Request/UserDefaultAddressRequestData'
import Dialog from '@/calendesk/models/Dialog'
import { DialogTypes } from '@/components/dialogs/DialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'

export default mixins(DraftElement, PaymentActions, dialogMixins, CompanyDataActions).extend({
  name: 'PayWithSubscriptionDialog',
  components: { CCountryAutocomplete, CPhoneInput },
  data () {
    return {
      formIsValid: false,
      product: null as SimpleStoreProduct | null,
      button: null as CtaButtonData | null,
      isLoading: false,
      isBuying: false,
      showPromoCode: false,
      promoCode: null,
      wantsAccount: false,
      selectedGdprFields: [] as GdprFieldUserData[],
      configurationData: null as Record<string, any> | null,
      rules: {
        required,
        email,
        minChars,
        maxChars,
        lowercase,
        uppercase,
        number,
        specialChar,
        equalsValue
      }
    }
  },
  computed: {
    showBookingNameAndSurnameFields (): boolean {
      if (this.configurationData && this.configurationData.configuration) {
        return this.configurationData.configuration.wb_show_name_surname__checkbox__
      }

      return true
    },
    showBookingPhoneNumberField (): boolean {
      if (this.configurationData && this.configurationData.configuration) {
        return this.configurationData.configuration.wb_show_phone_number__checkbox__
      }

      return true
    },
    phoneNumberRules (): any[] {
      if (this.configurationData && this.configurationData.configuration) {
        if (this.configurationData.configuration.wb_require_phone_number__checkbox__) {
          return [this.rules.required]
        }
      }

      return []
    },
    canDisplayBillingForm (): boolean {
      return (this.configurationData && this.configurationData.configuration &&
        this.configurationData.configuration.wb_show_billing_data__checkbox__)
    },
    requireBillingData (): boolean {
      return (this.configurationData && this.configurationData.configuration &&
        this.configurationData.configuration.wb_require_billing_data__checkbox__)
    }
  },
  created () {
    if (this.dialog.data && this.dialog.data.productId) {
      this.button = this.dialog.data.button as CtaButtonData
      this.configurationData = this.dialog.data.configurationData as Record<string, any>

      this.isLoading = true
      this.$emit('loading', this.isLoading)

      this.fetchSimpleStoreProduct(this.dialog.data.productId).then((product: SimpleStoreProduct) => {
        this.product = product
      }).catch((error: any) => {
        errorNotification(null, error, false)
        this.$emit('close')
      }).finally(() => {
        this.isLoading = false
        this.$emit('loading', this.isLoading)
      })
    } else {
      this.$emit('close')
    }
  },
  methods: {
    ...mapActions({
      fetchSimpleStoreProduct: 'payments/fetchSimpleStoreProduct',
      closeConfirmDialog: 'dialog/closeConfirmDialog'
    }),
    parseCheckboxDescription,
    gdprFieldChanged (element: GdprFieldData, selected: boolean) {
      const found = this.selectedGdprFields.find((field: GdprFieldUserData) => (field.name === element.name && field.label === element.label))

      if (found) {
        const index = this.selectedGdprFields.indexOf(found)
        this.selectedGdprFields.splice(index, 1)
      } else {
        const newElement = new GdprFieldUserData(element.name, element.label, element.required, selected)
        this.selectedGdprFields.push(newElement)
      }
    },
    async confirmAction () {
      (this.$refs as any).form.validate()

      if (!this.formIsValid || !this.product) {
        errorNotification('form_is_invalid', null, false)
        return
      }

      let gdprFields = null
      if (this.selectedGdprFields.length > 0) {
        gdprFields = JSON.stringify(this.selectedGdprFields)
      }

      const user = new CreateUserRequestData(
        this.email || this.user.email,
        this.name,
        this.surname,
        this.wantsAccount,
        this.phone)

      if (this.companyDataFilled) {
        user.default_address = new UserDefaultAddressRequestData()
        user.default_address.vat_iso_prefix = this.companyVatIsoPrefix
        user.default_address.tax_number = this.companyTaxNumber
        user.default_address.name = this.companyName
        user.default_address.street = this.companyStreet
        user.default_address.postal_code = this.companyPostalCode
        user.default_address.city = this.companyCity
        user.default_address.country_iso_code = this.companyCountryIsoCode
      }

      const request = new SimpleStoreProductCreatePaymentRequestData(
        user, this.product.id, 1, this.promoCode, gdprFields
      )

      this.isBuying = true
      this.paySimpleStoreProduct(request)
        .finally(() => {
          this.isBuying = false
        })
    },
    openLoginDialog (): void {
      this.openDialog2(new Dialog(true, DialogTypes.LOGIN, this.$trans('login'), DialogSize.MIDDLE, false, 'center'))
    }
  }
})
